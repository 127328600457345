
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import KanbanDetailsBlock from '@/components/pages/shared/kanban/details/KanbanDetailsBlock.vue'
import type { EditorButtonType } from '@/definitions/shared/editor/types'
import { editorBtns } from '@/definitions/shared/editor/data'
import DealsDetailsSidebarTimelineAction from '@/components/pages/deals/details/sidebar/timeline/DealsDetailsSidebarTimelineAction.vue'
import DealsDetailsSidebarTimelineReply from '@/components/pages/deals/details/sidebar/timeline/DealsDetailsSidebarTimelineReply.vue'
import { dealsTimelineItems } from '@/definitions/deals/data'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    TmScrollbar,
    DealsDetailsSidebarTimelineReply,
    DealsDetailsSidebarTimelineAction,
    TmAvatar,
    TmButton,
    KanbanDetailsBlock,
  },
  setup() {
    const { isSmMax } = useBreakpoints()
    const showEditor = ref(false)
    const comment = ref()

    const btnsList: EditorButtonType[] = [
      editorBtns.text,
      editorBtns.emoji,
    ]

    return {
      isSmMax,
      dealsTimelineItems,
      showEditor,
      btnsList,
      comment,
    }
  },
})
