import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_deals_details_sidebar_header = _resolveComponent("deals-details-sidebar-header")!
  const _component_deals_details_sidebar_timeline = _resolveComponent("deals-details-sidebar-timeline")!
  const _component_details_grid_sidebar = _resolveComponent("details-grid-sidebar")!

  return (_openBlock(), _createBlock(_component_details_grid_sidebar, {
    class: "deals-details-sidebar",
    "no-padding": "",
    "no-scroll": "",
    "small-header": _ctx.isMdMax
  }, {
    "sidebar-header": _withCtx(() => [
      _createVNode(_component_deals_details_sidebar_header, {
        "deal-closed": _ctx.dealClosed,
        "current-step": _ctx.currentStep,
        onMoveNextStage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('move-next-stage'))),
        onMovePrevStage: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('move-prev-stage'))),
        onDealWon: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('deal-won'))),
        onDealLost: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('deal-lost')))
      }, null, 8, ["deal-closed", "current-step"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_deals_details_sidebar_timeline)
    ]),
    _: 1
  }, 8, ["small-header"]))
}