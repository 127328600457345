import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_stylized_text = _resolveComponent("tm-stylized-text")!
  const _component_deals_details_sidebar_timeline_template = _resolveComponent("deals-details-sidebar-timeline-template")!

  return (_openBlock(), _createBlock(_component_deals_details_sidebar_timeline_template, null, {
    default: _withCtx(() => [
      _createVNode(_component_tm_stylized_text, { text: _ctx.text }, null, 8, ["text"])
    ]),
    _: 1
  }))
}