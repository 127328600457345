
import { defineComponent } from 'vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  setup() {
    const { isLgMax } = useBreakpoints()

    return {
      isLgMax,
    }
  },
})
