
import { computed, defineComponent, ref, watch } from 'vue'
import { dealsDetailsTimelines, dealsDetailsTimelinesWonItem, dealsDetailsTimelinesLostItem } from '@/definitions/deals/data'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import type { DealsDetailsProgressItem } from '@/definitions/deals/types'

export default defineComponent({
  components: {
    TmDropdownItem,
    TmDropdown,
  },
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
  },
  emits: ['deal-state', 'update:current-step'],
  setup(props, { emit }) {
    const items = ref(dealsDetailsTimelines)

    const getStyles = computed(() => {
      return {
        background: items.value[props.currentStep].background,
        color: items.value[props.currentStep].color,
      }
    })

    const nextStage = () => {
      if (props.currentStep >= items.value.length - 1) { return }
      emit('update:current-step', props.currentStep + 1)
    }

    const prevStage = () => {
      if (!props.currentStep) { return }
      emit('update:current-step', props.currentStep - 1)
    }

    watch(() => props.currentStep, (newVal) => {
      emit('deal-state', newVal >= items.value.length - 1)
    })

    const handleItemClick = (item: DealsDetailsProgressItem, index: number) => {
      if (item.disabled) { return }

      emit('update:current-step', index)
    }

    const updateItem = (itemIndex: number, dropdownItem: DealsDetailsProgressItem) => {
      items.value[itemIndex] = Object.assign(items.value[itemIndex], dropdownItem)
      emit('update:current-step', itemIndex)
    }

    const setActiveWon = () => {
      updateItem(items.value.length - 1, dealsDetailsTimelinesWonItem)
    }

    const setActiveLost = () => {
      updateItem(items.value.length - 1, dealsDetailsTimelinesLostItem)
    }

    return {
      setActiveWon,
      setActiveLost,
      nextStage,
      prevStage,
      handleItemClick,
      updateItem,
      getStyles,
      items,
      dealsDetailsTimelines,
    }
  },
})
