import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bc0668c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!

  return (_openBlock(), _createElementBlock("div", {
    class: "deals-details-progress",
    style: _normalizeStyle({
      '--timeline-background': _ctx.getStyles.background,
      '--timeline-color': _ctx.getStyles.color,
    })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: i,
        class: _normalizeClass(["deals-details-progress__item", {
        'deals-details-progress__item--active': i <= _ctx.currentStep,
      }]),
        onClick: ($event: any) => (_ctx.handleItemClick(item, i))
      }, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(item.dropdown ? 'tm-dropdown' : 'span'), { position: "bottom-left" }, {
          menu: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.dropdown, (dropdownItem, k) => {
              return (_openBlock(), _createBlock(_component_tm_dropdown_item, {
                key: k,
                "icon-class": dropdownItem.iconClass,
                label: dropdownItem.label,
                icon: dropdownItem.icon,
                onClick: ($event: any) => (_ctx.updateItem(i, dropdownItem.item))
              }, null, 8, ["icon-class", "label", "icon", "onClick"]))
            }), 128))
          ]),
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.label) + " ", 1),
            (item.dropdown)
              ? (_openBlock(), _createBlock(_component_tm_icon, {
                  key: 0,
                  name: "tmi-arrow-drop-down-sharp",
                  size: "xLarge"
                }))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024))
      ], 10, _hoisted_1))
    }), 128))
  ], 4))
}