
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { KanbanAttachmentType } from '@/definitions/shared/kanban/types'
import KanbanDetailsBlock from '@/components/pages/shared/kanban/details/KanbanDetailsBlock.vue'
import TmButton from '@/components/shared/TmButton.vue'
import KanbanDetailsAttachment from '@/components/pages/shared/kanban/details/attachments/KanbanDetailsAttachment.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    KanbanDetailsAttachment,
    TmButton,
    KanbanDetailsBlock,
  },
  props: {
    attachments: {
      type: Array as PropType<KanbanAttachmentType[]>,
    },
  },
  setup() {
    const { isLgMax } = useBreakpoints()

    return {
      isLgMax,
    }
  },
})
