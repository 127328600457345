
import { defineComponent, ref } from 'vue'
import DealsDetailsSidebarTimelineTemplate from '@/components/pages/deals/details/sidebar/timeline/DealsDetailsSidebarTimelineTemplate.vue'
import { useModals } from '@/compositions/modals'
import TmEmoji from '@/components/shared/TmEmoji.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'

export default defineComponent({
  components: {
    TmTooltip,
    TmEmoji,
    DealsDetailsSidebarTimelineTemplate,
  },
  props: {
    text: {
      type: String,
    },
  },
  setup() {
    const { openModal } = useModals()
    const showReactions = ref(false)
    const openDeleteModal = () => {
      openModal('confirmation', {
        title: 'Delete comment',
        btnText: 'Delete',
        btnColor: 'error',
        text: 'Are you sure you want to delete this comment? This action cannot be undone.',
      })
    }

    return {
      openDeleteModal,
      showReactions,
    }
  },
})
