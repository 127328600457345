import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7fdb42be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "deals-details-modal__main" }
const _hoisted_2 = { class: "deals-details-modal__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_deals_details_navigation = _resolveComponent("deals-details-navigation")!
  const _component_deals_details_header = _resolveComponent("deals-details-header")!
  const _component_deals_details_progress = _resolveComponent("deals-details-progress")!
  const _component_deals_details_main = _resolveComponent("deals-details-main")!
  const _component_deals_details_mobile = _resolveComponent("deals-details-mobile")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_deals_details_sidebar = _resolveComponent("deals-details-sidebar")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "dealsDetails",
    "exact-size": _ctx.exactSize,
    "custom-styles": _ctx.modalStyles,
    "no-footer": "",
    "no-header": "",
    "no-scroll": "",
    "without-content-padding": ""
  }, {
    aside: _withCtx(() => [
      _createVNode(_component_deals_details_navigation)
    ]),
    "modal-content": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_deals_details_header, {
            onToggleSidebar: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sidebarVisible = !_ctx.sidebarVisible))
          }),
          _createVNode(_component_deals_details_progress, {
            ref: "timelineRef",
            "current-step": _ctx.currentStep,
            "onUpdate:current-step": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentStep) = $event)),
            class: "gt-sm",
            onDealState: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dealClosed = $event))
          }, null, 8, ["current-step"]),
          _createVNode(_component_deals_details_main, { class: "gt-sm" }),
          _createVNode(_component_deals_details_mobile, {
            class: "lt-md",
            "deal-closed": _ctx.dealClosed,
            "current-step": _ctx.currentStep,
            onMoveNextStage: _ctx.moveNextStage,
            onMovePrevStage: _ctx.movePrevStage,
            onDealWon: _ctx.handleDealWon,
            onDealLost: _ctx.handleDealLost
          }, null, 8, ["deal-closed", "current-step", "onMoveNextStage", "onMovePrevStage", "onDealWon", "onDealLost"])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["deals-details-modal__sidebar", {
            'deals-details-modal__sidebar--visible': _ctx.sidebarVisible,
          }])
        }, [
          _createElementVNode("div", {
            class: "deals-details-modal__sidebar-collapse",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.sidebarVisible = !_ctx.sidebarVisible))
          }, [
            _createVNode(_component_tm_icon, { name: "chevron_right" })
          ]),
          _createVNode(_component_deals_details_sidebar, {
            "deal-closed": _ctx.dealClosed,
            "current-step": _ctx.currentStep,
            onMoveNextStage: _ctx.moveNextStage,
            onMovePrevStage: _ctx.movePrevStage,
            onDealWon: _ctx.handleDealWon,
            onDealLost: _ctx.handleDealLost
          }, null, 8, ["deal-closed", "current-step", "onMoveNextStage", "onMovePrevStage", "onDealWon", "onDealLost"])
        ], 2)
      ])
    ]),
    _: 1
  }, 8, ["exact-size", "custom-styles"]))
}