
import { defineComponent, ref, computed } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'

import DealsDetailsMain from '@/components/pages/deals/details/DealsDetailsMain.vue'
import DealsDetailsSidebar from '@/components/pages/deals/details/sidebar/DealsDetailsSidebar.vue'
import DealsDetailsProgress from '@/components/pages/deals/details/DealsDetailsProgress.vue'
import DealsDetailsMobile from '@/components/pages/deals/details/DealsDetailsMobile.vue'
import DealsDetailsNavigation from '@/components/pages/deals/details/DealsDetailsNavigation.vue'
import DealsDetailsHeader from '@/components/pages/deals/details/DealsDetailsHeader.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    DealsDetailsHeader,
    DealsDetailsNavigation,
    DealsDetailsMobile,
    DealsDetailsProgress,
    DealsDetailsMain,
    DealsDetailsSidebar,
    TmModal,
  },
  setup() {
    const { is3XlMax, isSmMax, isLgMax } = useBreakpoints()
    const sidebarVisible = ref(false)
    const timelineRef = ref()
    const dealClosed = ref(false)
    const currentStep = ref(0)

    const moveNextStage = () => {
      timelineRef.value.nextStage()
    }

    const movePrevStage = () => {
      timelineRef.value.prevStage()
    }

    const handleDealWon = () => {
      timelineRef.value.setActiveWon()
    }

    const handleDealLost = () => {
      timelineRef.value.setActiveLost()
    }

    const exactSize = computed(() => {
      if (isSmMax.value) { return 'calc(100% - 16px)' }
      if (isLgMax.value) { return 'calc(100% - 24px)' }
      if (is3XlMax.value) { return 'calc(100% - 28px)' }
      return '1400px'
    })

    const modalStyles = computed(() => {
      if (isSmMax.value) { return { marginRight: '16px' } }
      if (isLgMax.value) { return { marginRight: '24px', marginLeft: '8px' } }
      if (is3XlMax.value) { return { marginRight: '28px', marginLeft: '8px' } }
      return {}
    })

    return {
      sidebarVisible,
      exactSize,
      modalStyles,
      currentStep,
      handleDealWon,
      handleDealLost,
      moveNextStage,
      movePrevStage,
      timelineRef,
      dealClosed,
    }
  },
})
