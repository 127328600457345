import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aef0b42e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "deals-details-sidebar-timeline-template" }
const _hoisted_2 = { class: "flex-grow-1" }
const _hoisted_3 = { class: "d-flex w100pr justify-between" }
const _hoisted_4 = { class: "body-text-semi-bold-14 lh-20" }
const _hoisted_5 = { class: "body-text-regular-12 lh-20 distinct--text" }
const _hoisted_6 = { class: "lh-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_avatar, {
      name: _ctx.person.fullName,
      color: _ctx.person.avatarColor,
      url: _ctx.person.avatar,
      class: "mr-2 gt-lg"
    }, null, 8, ["name", "color", "url"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.person.fullName), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.date), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ])
  ]))
}