import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d2aceec6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "deals-details-overview-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_fields_container = _resolveComponent("tm-fields-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_tm_fields_container, {
        modelValue: _ctx.assigneeValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.assigneeValue) = $event)),
        label: "Deal owner",
        type: "assignee",
        size: "small",
        "avatar-size": "xxSmall",
        options: _ctx.assigneeOptions,
        placeholder: "Assign to"
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_tm_fields_container, {
        modelValue: _ctx.statusValue,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.statusValue) = $event)),
        label: "Stage",
        type: "selectStatus",
        options: _ctx.dealsStatuses
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_tm_fields_container, {
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value) = $event)),
        label: "Est. value",
        placeholder: "Add value"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_tm_fields_container, {
        modelValue: _ctx.date,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.date) = $event)),
        label: "Est. close date",
        type: "datepicker",
        placeholder: "Add estimated close date",
        "hide-calendar-icon": ""
      }, null, 8, ["modelValue"]),
      _createVNode(_component_tm_fields_container, {
        modelValue: _ctx.probability,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.probability) = $event)),
        label: "Probability",
        placeholder: "Type probability"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_tm_fields_container, {
        modelValue: _ctx.contactValue,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.contactValue) = $event)),
        label: "Contact person",
        type: "assignee",
        size: "small",
        "avatar-size": "xxSmall",
        options: _ctx.assigneeOptions,
        placeholder: "Assign to"
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_tm_fields_container, {
        modelValue: _ctx.job,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.job) = $event)),
        label: "Job title",
        placeholder: "Add title"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_tm_fields_container, {
        modelValue: _ctx.email,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.email) = $event)),
        label: "Contact email",
        type: "email",
        "one-email": ""
      }, null, 8, ["modelValue"]),
      _createVNode(_component_tm_fields_container, {
        modelValue: _ctx.phone,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.phone) = $event)),
        label: "Contact phone",
        type: "phone",
        "one-phone": ""
      }, null, 8, ["modelValue"]),
      _createVNode(_component_tm_fields_container, {
        modelValue: _ctx.organization,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.organization) = $event)),
        label: "Organization",
        "avatar-size": "xxSmall",
        type: "selectOrganization",
        options: _ctx.organizationsOptions
      }, null, 8, ["modelValue", "options"])
    ])
  ]))
}