
import { defineComponent, ref } from 'vue'
import TmFieldsContainer from '@/components/shared/tmFieldsContainer/TmFieldsContainer.vue'
import { assigneeOptions, organizationsOptions } from '@/definitions/_general/_data/optionsList'
import { dealsStatuses } from '@/definitions/deals/data'

export default defineComponent({
  components: {
    TmFieldsContainer,
  },
  setup() {
    const assigneeValue = ref<any>(assigneeOptions[2])
    const contactValue = ref<any>(assigneeOptions[3])

    const date = ref(new Date())
    const value = ref('$1,400.00')
    const organization = ref(organizationsOptions[0])
    const statusValue = ref(dealsStatuses[0])
    const job = ref('Product manager')
    const probability = ref('10%')

    const email = ref([{ email: 'j.crowford@mail.com', type: 'Work' }])

    const phone = ref([{ phone: '(990) 338-9983', type: 'Work' }])

    return {
      phone,
      email,
      probability,
      job,
      statusValue,
      dealsStatuses,
      organization,
      organizationsOptions,
      value,
      assigneeOptions,
      date,
      assigneeValue,
      contactValue,
    }
  },
})
