import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36bd388c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "kanban-details-attachment" }
const _hoisted_2 = { class: "flex-grow-1" }
const _hoisted_3 = { class: "kanban-details-attachment__title" }
const _hoisted_4 = { class: "kanban-details-attachment__info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_file = _resolveComponent("tm-file")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_file, {
      url: _ctx.url,
      file: _ctx.file
    }, null, 8, ["url", "file"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", null, _toDisplayString(_ctx.date), 1)
      ])
    ]),
    _createVNode(_component_tm_button, {
      "icon-only": "",
      color: "transparent",
      flat: "",
      size: "small",
      onClick: _ctx.openDeleteFileModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_icon, {
          name: "delete",
          class: "distinct--text"
        })
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}