import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_kanban_details_checklist = _resolveComponent("kanban-details-checklist")!

  return (_openBlock(), _createBlock(_component_kanban_details_checklist, {
    checklist: _ctx.checklist,
    "onUpdate:checklist": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checklist) = $event)),
    icon: "tmi-checkbox-alt-circle",
    "checklist-title": "Activities",
    "checklist-item-placeholder": "Type your activity",
    "checklist-item-name": "activity",
    "hide-delete-action": "",
    onAddItem: _ctx.addCheckListItem
  }, null, 8, ["checklist", "onAddItem"]))
}