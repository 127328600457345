import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-grow-1 overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_deals_details_overview = _resolveComponent("deals-details-overview")!
  const _component_deals_details_activities = _resolveComponent("deals-details-activities")!
  const _component_kanban_details_attachments = _resolveComponent("kanban-details-attachments")!
  const _component_tm_scrollbar = _resolveComponent("tm-scrollbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_scrollbar, { "remove-scrollbar-width": "" }, {
      default: _withCtx(() => [
        _createVNode(_component_deals_details_overview),
        _createVNode(_component_deals_details_activities),
        _createVNode(_component_kanban_details_attachments, { attachments: _ctx.dealsAttachments }, null, 8, ["attachments"])
      ]),
      _: 1
    })
  ]))
}