
import { defineComponent } from 'vue'
import DealsDetailsSidebarTimelineTemplate from '@/components/pages/deals/details/sidebar/timeline/DealsDetailsSidebarTimelineTemplate.vue'
import TmStylizedText from '@/components/shared/TmStylizedText.vue'
import type { TextArray } from '@/definitions/shared/types'
import type { PropType } from 'vue'

export default defineComponent({
  components: {
    TmStylizedText,
    DealsDetailsSidebarTimelineTemplate,
  },
  props: {
    text: {
      type: [String, Array] as PropType<string | TextArray[]>,
    },
  },
})
