
import { defineComponent } from 'vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import type { PersonType } from '@/definitions/shared/types'
import type { PropType } from 'vue'

export default defineComponent({
  components: {
    TmAvatar,
  },
  props: {
    person: {
      type: Object as PropType<PersonType>,
    },
    date: {
      type: String,
    },
  },
})
