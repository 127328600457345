
import { defineComponent, ref } from 'vue'
import KanbanDetailsBlock from '@/components/pages/shared/kanban/details/KanbanDetailsBlock.vue'
import type { EditorButtonType } from '@/definitions/shared/editor/types'
import { editorBtns } from '@/definitions/shared/editor/data'
import TmButton from '@/components/shared/TmButton.vue'
import DealsDetailsOverviewInfo from '@/components/pages/deals/details/overview/DealsDetailsOverviewInfo.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    DealsDetailsOverviewInfo,
    KanbanDetailsBlock,
    TmButton,
  },
  setup() {
    const { isLgMax } = useBreakpoints()
    const showEditor = ref(false)
    const text = ref('Acme Corp is interested in upgrading to the Enterprise Plan with SMS marketing and API integration. Potential upsell of $15,000 annually. Product demo for their IT team is next, with expected close by December 15th, pending procurement approval.')

    const btnsList: EditorButtonType[] = [
      editorBtns.text,
      editorBtns.emoji,
    ]

    return {
      showEditor,
      text,
      isLgMax,
      btnsList,
    }
  },
})
