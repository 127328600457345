
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { TmFileExtension } from '@/definitions/shared/types'
import TmFile from '@/components/shared/TmFile.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TmButton,
    TmFile,
  },
  props: {
    url: {
      type: String,
    },
    file: {
      type: String as PropType<TmFileExtension>,
    },
    title: {
      type: String,
    },
    date: {
      type: String,
    },
  },
  setup() {
    const { openModal } = useModals()

    const openDeleteFileModal = () => {
      openModal('confirmation', {
        title: 'Delete file',
        btnText: 'Delete',
        btnColor: 'error',
        text: 'Are you sure you want to delete this file? This action cannot be undone.',
      })
    }

    return {
      openDeleteFileModal,
    }
  },
})
