
import { defineComponent, ref } from 'vue'
import TmEditableText from '@/components/shared/TmEditableText.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { notify } from '@kyvg/vue3-notification'

export default defineComponent({
  components: {
    TmEditableText,
    TmButton,
  },
  emits: ['toggle-sidebar'],
  setup() {
    const title = ref('Acme Corp - Q4 Upgrade')
    const copyLink = () => {
      notify({
        text: 'The link has been successfully copied.',
        type: 'success',
      })
    }

    return {
      copyLink,
      title,
    }
  },
})
