
import { defineComponent } from 'vue'
import DetailsGridSidebar from '@/components/shared/templates/details/DetailsGridSidebar.vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import DealsDetailsSidebarHeader from '@/components/pages/deals/details/sidebar/DealsDetailsSidebarHeader.vue'
import DealsDetailsSidebarTimeline from '@/components/pages/deals/details/sidebar/DealsDetailsSidebarTimeline.vue'

export default defineComponent({
  components: {
    DealsDetailsSidebarTimeline,
    DealsDetailsSidebarHeader,
    DetailsGridSidebar,
  },
  props: {
    dealClosed: {
      type: Boolean,
    },
    currentStep: {
      type: Number,
    },
  },
  emits: ['move-next-stage', 'move-prev-stage', 'deal-won', 'deal-lost'],
  setup() {
    const { isMdMax } = useBreakpoints()

    return {
      isMdMax,
    }
  },
})
