import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-647df1e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "deals-details-mobile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_deals_details_sidebar_header = _resolveComponent("deals-details-sidebar-header")!
  const _component_deals_details_overview = _resolveComponent("deals-details-overview")!
  const _component_tm_scrollbar = _resolveComponent("tm-scrollbar")!
  const _component_deals_details_activities = _resolveComponent("deals-details-activities")!
  const _component_kanban_details_attachments = _resolveComponent("kanban-details-attachments")!
  const _component_deals_details_sidebar_timeline = _resolveComponent("deals-details-sidebar-timeline")!
  const _component_tm_tabs = _resolveComponent("tm-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_deals_details_sidebar_header, {
      "current-step": _ctx.currentStep,
      "deal-closed": _ctx.dealClosed,
      class: "mb-4",
      onMoveNextStage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('move-next-stage'))),
      onMovePrevStage: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('move-prev-stage'))),
      onDealWon: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('deal-won'))),
      onDealLost: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('deal-lost')))
    }, null, 8, ["current-step", "deal-closed"]),
    _createVNode(_component_tm_tabs, {
      tabs: _ctx.tabs,
      "tabs-nav-class": "px-4",
      "with-dropdown": ""
    }, {
      "panel-overview": _withCtx(() => [
        _createVNode(_component_tm_scrollbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_deals_details_overview)
          ]),
          _: 1
        })
      ]),
      "panel-activities": _withCtx(() => [
        _createVNode(_component_tm_scrollbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_deals_details_activities)
          ]),
          _: 1
        })
      ]),
      "panel-files": _withCtx(() => [
        _createVNode(_component_tm_scrollbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_kanban_details_attachments, { attachments: _ctx.dealsAttachments }, null, 8, ["attachments"])
          ]),
          _: 1
        })
      ]),
      "panel-timeline": _withCtx(() => [
        _createVNode(_component_deals_details_sidebar_timeline)
      ]),
      _: 1
    }, 8, ["tabs"])
  ]))
}