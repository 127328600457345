
import { defineComponent } from 'vue'
import DealsDetailsOverview from '@/components/pages/deals/details/overview/DealsDetailsOverview.vue'
import KanbanDetailsAttachments from '@/components/pages/shared/kanban/details/attachments/KanbanDetailsAttachments.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import DealsDetailsActivities from '@/components/pages/deals/details/DealsDetailsActivities.vue'
import { dealsAttachments } from '@/definitions/deals/data'

export default defineComponent({
  components: {
    DealsDetailsActivities,
    TmScrollbar,
    KanbanDetailsAttachments,
    DealsDetailsOverview,
  },
  setup() {
    return {
      dealsAttachments,
    }
  },
})
