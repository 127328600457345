
import { defineComponent, ref } from 'vue'
import DealsDetailsSidebarHeader from '@/components/pages/deals/details/sidebar/DealsDetailsSidebarHeader.vue'
import TmTabs from '@/components/shared/TmTabs.vue'
import DealsDetailsOverview from '@/components/pages/deals/details/overview/DealsDetailsOverview.vue'
import DealsDetailsActivities from '@/components/pages/deals/details/DealsDetailsActivities.vue'
import KanbanDetailsAttachments from '@/components/pages/shared/kanban/details/attachments/KanbanDetailsAttachments.vue'
import { dealsAttachments } from '@/definitions/deals/data'
import DealsDetailsSidebarTimeline from '@/components/pages/deals/details/sidebar/DealsDetailsSidebarTimeline.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'

export default defineComponent({
  components: {
    TmScrollbar,
    DealsDetailsSidebarTimeline,
    DealsDetailsActivities,
    DealsDetailsOverview,
    TmTabs,
    KanbanDetailsAttachments,
    DealsDetailsSidebarHeader,
  },
  props: {
    dealClosed: {
      type: Boolean,
    },
    currentStep: {
      type: Number,
    },
  },
  emits: ['move-next-stage', 'move-prev-stage', 'deal-won', 'deal-lost'],
  setup() {
    const tabs = ref([
      {
        name: 'overview',
        label: 'Overview',
      },
      {
        name: 'activities',
        label: 'Activities',
      },
      {
        name: 'files',
        label: 'Attached files',
      },
      {
        name: 'timeline',
        label: 'Timeline',
      },
    ])

    return {
      tabs,
      dealsAttachments,
    }
  },
})
