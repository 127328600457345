
import { defineComponent, ref } from 'vue'
import KanbanDetailsChecklist from '@/components/pages/shared/kanban/details/KanbanDetailsChecklist.vue'
import { dealsChecklist } from '@/definitions/deals/data'

export default defineComponent({
  components: {
    KanbanDetailsChecklist,
  },
  setup() {
    const checklist = ref(dealsChecklist)

    const addCheckListItem = () => {
      checklist.value.push({
        title: '',
        isEditable: true,
        checked: false,
        dropdownActive: false,
      })
    }

    return {
      checklist,
      addCheckListItem,
    }
  },
})
