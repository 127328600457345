
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import { useDeals } from '@/compositions/deals/useDeals'
import TmStatus from '@/components/shared/TmStatus.vue'
import { dealsDetailsTimelines, dealsStatuses } from '@/definitions/deals/data'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    TmStatus,
    TmButton,
    TmDropdown,
    TmDropdownItem,
  },
  props: {
    dealClosed: {
      type: Boolean,
    },
    currentStep: {
      type: Number,
    },
  },
  emits: ['move-next-stage', 'move-prev-stage', 'deal-won', 'deal-lost'],
  setup() {
    const { openDeleteDeal } = useDeals()
    const { isXXlMax } = useBreakpoints()

    return {
      isXXlMax,
      openDeleteDeal,
      dealsStatuses,
      dealsDetailsTimelines,
    }
  },
})
