import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-250c3a02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "deals-details-navigation" }
const _hoisted_2 = { class: "deals-details-navigation__action" }
const _hoisted_3 = { class: "deals-details-navigation__action" }
const _hoisted_4 = { class: "deals-details-navigation__action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      _createVNode(_component_tm_icon, {
        name: "close",
        "custom-size": _ctx.isLgMax ? '24px' : '28px'
      }, null, 8, ["custom-size"])
    ])), [
      [_directive_close_popup]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_tm_icon, {
        name: "chevron_left",
        "custom-size": _ctx.isLgMax ? '30px' : '36px'
      }, null, 8, ["custom-size"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_tm_icon, {
        name: "chevron_right",
        "custom-size": _ctx.isLgMax ? '30px' : '36px'
      }, null, 8, ["custom-size"])
    ])
  ]))
}